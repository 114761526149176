.bienvenue {
  .header-background {
    position: relative;

    .content-wrapper {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;


      .background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .gradiant-background {
        position: absolute;
        top: 0;
        bottom: 0;
        background: linear-gradient(77.82deg, rgba(22, 42, 65, 0.96) 10.03%, rgba(22, 42, 65, 0) 92.05%);
        width: 900px;
        height: 100%;
      }
    }

    background-size: 100% 120%;
    background-repeat: no-repeat;
    max-height: 700px;
    //height: 55vh;
    min-height: 400px;

    .title-container {
      padding: 10em 3em 6em 3em;
      max-width: 700px;

      h1 {
        color: #FBF0E5;
        font-family: YesevaOne;
        font-size: 2.5em;
        line-height: 1.1em;
        margin-bottom: 2rem;
        font-weight: 400;
      }

      h5 {
        color: #FBF0E5;
        font-family: "medium";
        line-height: 1.3em;
        font-size: 1.1em;
        font-weight: 600;
        max-width: 550px;
      }
    }

  }

  .premier-voyage {
    position: relative;
    background: linear-gradient(90deg, #3974C0 0%, #6C3490 100%);

    .section-wrapper {
      padding: unset;
      display: flex;
      flex-direction: column;
      padding-bottom: 4em;

      .info-container {
        padding: 4em 0 0em 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2.1rem;

        h3 {
          font-family: YesevaOne;
          color: #FBF0E5;
          font-size: 2.5em;
        }

        p {
          font-family: semi-bold;
          color: #FBF0E5;
          font-size: 1.1em;
          font-weight: 600;
        }


      }

      .cadeau {
        width: 100%;
        position: relative;

        .cadeau-content-wrapper {
          display: flex;
          flex: 1;

          .cadeau-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            flex-grow: 3;

            .icon-background {
              max-width: 20vw;
              max-height: 20vw;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100px;
              width: 100px;
              background-color: #C7C4DE;
              border-radius: 100%;
              z-index: 10;
            }

            .cadeau-description {
              font-family: semi-bold;
              color: #FBF0E5;
              max-width: 300px;
              text-align: center;
              font-weight: 600;
              line-height: 1.2em;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .discover-inexplore {
    position: relative;

    .content-wrapper {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;


      .background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .gradiant-background {
        position: absolute;
        top: 0;
        bottom: 0;
        background: linear-gradient(77.82deg, rgba(22, 42, 65, 0.96) 10.03%, rgba(22, 42, 65, 0) 92.05%);
        width: 900px;
        height: 100%;
      }

    }

    .section-wrapper {
      display: flex;
      flex-direction: column;
      padding: 4em 3em 4em 3em;
      svg {
        max-width: 80vw;
      }

      h5 {
        font-family: semi-bold;
        font-weight: 600;
        color: #FBF0E5;
        font-size: 1.2em;
        margin-bottom: 1em;
      }

      .inexplore-content-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 100em;
        flex-wrap: wrap;
        gap: 3em;

        .content-box {
          padding: 30px 15px 40px 15px;
          background-color: #FBF0E5;
          border-radius: 10px;
          width: 360px;
          position: relative;

          .content-box-blur {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #FBF0E5;
            filter: blur(6px);
            z-index: -1;
          }

          .content-header {
            margin-bottom: 1.5em;
            display: flex;
            gap: 20px;
            align-items: center;

            & > svg {
              //margin-top: 0.2em;
            }

            .content-title {
              display: flex;
              flex-direction: column;
              gap: 0.5em;

              h6 {
                background: linear-gradient(90deg, #21639E 0%, #6C3490 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: semi-bold;
                font-size: 1.6em;
              }

              p {
                background: linear-gradient(90deg, #21639E 0%, #6C3490 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: medium;
                font-style: italic;
                font-weight: 600;
                font-size: 1.1em;
              }
            }
          }

          .content-description {
            display: flex;
            flex-direction: column;
            gap: 1.5em;

            .content-line {
              display: flex;
              gap: 0.5em;

              p {
                background: linear-gradient(90deg, #21639E 0%, #6C3490 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: semi-bold;
                font-size: 1.1em;
                line-height: 1.2em;
              }

              .content-dot {
                height: 5px;
                width: 6px;
                border-radius: 100%;
                background-color: #21639E;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .bienvenue-themes {
    background: linear-gradient(90deg, #3974C0 0%, #6C3490 100%);
    padding: 4em 0 4em 0em;
    position: relative;

    .background-overflow {
      opacity: 0.3;
      background: var(--Black, #182B43);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      z-index: 0;
    }

    h3 {
      font-family: YesevaOne;
      color: #FBF0E5;
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 1.5em;
      z-index: 1;
      position: relative;
    }

    .swiper-wrapper {
      .swiper-slide:first-child {
        margin-left: 4em;
      }

      .swiper-slide:last-child {
        margin-right: 4em;
      }

      .swiper-slide {
        background-color: #162A41B2;
        height: 170px;
        width: 170px;
        border-radius: 10px;
        padding: 18px 13px;
        user-select: none;
        cursor: grab;

        svg {
          height: 38px;
          width: 38px;
        }

        h4 {
          color: #FFFFFF;
          font-family: semi-bold;
          font-weight: 600;
          margin: 0.9em 0;
          font-size: 1.1em;
        }

        p {
          font-family: medium;
          color: rgba(255, 255, 254, 0.6);
          line-height: 1.1em;
          font-size: 14px;
        }
      }
    }
  }

  .slider {
    .custom-arrow {
      width: 24px;
      height: 24px;
      background-color: #36435D;
      position: absolute;
      outline: 0;
      transition: all .5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 50%;
      transform: translate(0, -30%);
    }

    .react-multi-carousel-track {
      cursor: grab;

    }

    .custom-left-arrow {
      left: calc(4% + 1px);
    }

    .custom-right-arrow {
      right: calc(4% + 1px);
    }

    .react-multi-carousel-list {
      min-height: 240px;
      //overflow: visible;
      align-items: flex-start;
    }

    .react-multi-carousel-dot-list {
      //bottom: -60px;

      .react-multi-carousel-dot button {
        border: none;
        background-color: rgba(255, 255, 255, 0.20);
        width: 8px;
        height: 8px;
      }

      .react-multi-carousel-dot--active button {
        background-color: white;
      }
    }
  }

  .bienvenue-intervenants {
    background: linear-gradient(90deg, #3974C0 0%, #6C3490 100%);
    padding: 4em 0 4em 0em;
    position: relative;

    h3 {
      font-family: YesevaOne;
      color: #FBF0E5;
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 1.5em;
      z-index: 1;
      position: relative;
    }

    .swiper-wrapper {
      .swiper-slide:first-child {
        margin-left: 4em;
      }

      .swiper-slide:last-child {
        margin-right: 4em;
      }

      .swiper-slide {
        height: 170px;
        width: 170px;
        border-radius: 10px;
        padding: 18px 13px;
        user-select: none;
        display: flex;
        align-items: center;
        flex-direction: column;

        .block-icone-slide img {
          border-radius: 100%;
          height: 6.6em;
          width: 6.6em;
        }

        h4 {
          color: #FFFFFF;
          font-family: semi-bold;
          font-weight: 600;
          margin: 0.5em 0;
          font-size: 1em;
          text-align: center;
        }

        p {
          font-family: medium;
          color: rgba(255, 255, 254, 0.6);
          line-height: 0.8em;
          text-align: center;
        }
      }
    }
  }

  .bienvenue-connaissance {
    background: linear-gradient(90deg, #3974C0 0%, #6C3490 100%);
    padding: 4em 0 4em 0em;
    position: relative;

    .background-overflow {
      opacity: 0.3;
      background: #182B43;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      z-index: 0;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3em;

      h3 {
        font-family: YesevaOne;
        color: #FBF0E5;
        font-size: 2.5em;
        text-align: center;
        margin-bottom: 1em;
        z-index: 1;
        position: relative;
      }

      h5 {
        font-family: semi-bold;
        color: #FBF0E5;
        font-size: 1.2em;
        text-align: center;
        z-index: 1;
        margin-bottom: 2em;
        position: relative;
      }
      p {
        font-family: medium;
        color: #FBF0E5;
        font-size: 1em;
        text-align: center;
        z-index: 1;
        margin-top: 2em;
        position: relative;
        font-style: italic;
      }
    }
  }

  .email-section {
    display: flex;
    gap: 1.2rem;
    flex-wrap: wrap;
    justify-content: center;

    input {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 80vw;
      width: 300px;
      height: 50px;
      background-color: #ffffff;
      border-radius: 30px;
      margin-left: 10px;
      font-family: semi-bold;
      font-size: 1em;
      outline: none;
      box-shadow: none;
      border: none;
      color: #363b5a;

      &::placeholder {
        color: #162A418F;
        opacity: 1; /* Firefox */
      }
    }

    button {
      background-color: #a5d0c9;
      border: none;
      border-radius: 30px;
      font-family: semi-bold;
      color: #182B43;
      padding: 0 40px;
      font-weight: 700;
      cursor: pointer;
      font-size: 0.9em;
      transition: all 0.3s;
      height: 50px;

      &:hover {
        background-color: #182B43;
        color: #FBF0E5;
      }
    }
  }
}
