@import "../base/_var";
@import "../base/_fonts";

.qui-sommes-nous-header {
  background-image: /* linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.09) 0%,
      rgba(0, 0, 0, 0.48) 35.65%,
      rgba(0, 0, 0, 0.43) 100%
    ), */ url("../../images/qui-sommes-nous/header-background-image.jpg");
  #shape-qui-sommes-nous {
    bottom: -2px;
  }
}

.wave {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

#facebook {
  content: url("../../images/svg/facebook.svg");
}
#twitter {
  content: url("../../images/svg/twitter.svg");
}
#instagram {
  content: url("../../images/svg/instagram.svg");
}

.section-wrapper {
  padding: 1em 3em;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 3em;
  row-gap: 4em;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $breakTab) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3em;
  }
  @media screen and (max-width: $breakDesktop) {
    padding: 0em 6%;
  }
}

.container-qui-somme-nous {
  position: relative;
  height: auto;
  width: 100%;
}

.section-wrapper-associates {
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  column-gap: 1.5em;
  @media screen and (max-width: $breakMobile) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1em;
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.round {
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.round:hover {
  transform: scale(1.1);
}

h2 {
  @media screen and (max-width: $breakMobile) {
    font-size: 2em;
  }
  @media screen and (max-width: $breakSmallTab) {
    padding-top: 0.75em;
  }
  @media screen and (min-width: $breakSmallTabMin) and (max-width: 1229px) {
    font-size: 2em;
  }
  font-size: 2.5em;
  font-family: bold;
}

.co-founder-container {
  background-color: #e8e8eb;
  .co-founder-wrapper {
    position: relative;
    z-index: 2;
    min-height: 500px;
    bottom: 100px;
    align-items: flex-start;
    background: linear-gradient(90deg, #21639e 0%, #6c3490 100%);
    clip-path: polygon(0 0%, 100% 0, 100% 90%, 0 100%);
    padding: 1em 10% 8% 10%;
    @media screen and (max-width: $breakMobile) {
      bottom: 10px;
      clip-path: polygon(0 0%, 100% 0, 100% 95%, 0 100%);
      padding: 1em 6% 20% 6%;
    }
    @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
      bottom: 40px;
      clip-path: polygon(0 0%, 100% 0, 100% 95%, 0 100%);
      padding: 1em 6% 10% 6%;
    }
    @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
      bottom: 60px;
    }

    .text-container {
      @media screen and (max-width: $breakTab) {
        order: 1;
      }
      h3 {
        font-size: 2.875em;
        font-family: bold;
        color: $white;
        margin-bottom: 0.5em;
        @media screen and (max-width: $breakMobile) {
          font-size: 2em;
        }
      }
      h4 {
        font-size: 2.125em;
        font-family: bold;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 0.5em;
        @media screen and (max-width: $breakMobile) {
          font-size: 1.875em;
        }
      }
      p {
        text-align: left;
        font-size: 1em;
        line-height: 1.125em;
        font-family: semi-bold;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 2em;
        white-space: pre-line;
      }
    }
    .co-founder-image {
      justify-content: flex-start;
      align-items: center;
      .image {
        width: 300px;
        height: 300px;
        border: 8px solid $green;
        @media screen and (max-width: $breakMobile) {
          width: 200px;
          height: 200px;
          border: 5px solid $green;
        }
        img {
          object-fit: cover;
          object-position: left;
        }
      }
    }
  }
}
.associates-container {
  bottom: 0;
  align-items: flex-start;
  background-color: #e8e8eb;
  padding: 0 6% 8% 6%;
  min-height: 300px;
  @media screen and (max-width: $breakMobile) {
    padding: 2% 6% 15% 6%;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    padding: 0 6% 10% 6%;
  }
  @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
    padding: 0 3% 8% 3%;
  }
  .multiple-associates-image {
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    .image {
      width: 200px;
      height: 200px;
      border: 5px solid $purple;
      img {
        object-fit: cover;
        object-position: left;
      }
    }
  }
  .single-associates-image {
    justify-content: flex-start;
    align-items: center;
    .image {
      width: 300px;
      height: 300px;
      border: 8px solid $purple;
      @media screen and (max-width: $breakMobile) {
        width: 200px;
        height: 200px;
        border: 5px solid $purple;
      }
      img {
        object-fit: cover;
        object-position: left;
      }
    }
  }
  .text-container {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 2;
    span {
      font-size: 1.5em;
      font-family: bold;
      color: $blueTitle;
      margin-bottom: 0.5em;
    }
    h3 {
      font-size: 2.5em;
      font-family: bold;
      color: $purple;
      margin-bottom: 0.35em;
      @media screen and (max-width: $breakMobile) {
        font-size: 1.875em;
      }
    }
    h4 {
      font-size: 2.125em;
      font-family: bold;
      color: $grey;
      margin-bottom: 0.5em;
      @media screen and (max-width: $breakMobile) {
        font-size: 1.875em;
      }
    }
    p {
      text-align: left;
      max-width: 100%;
      font-size: 1em;
      line-height: 1.125em;
      font-family: semi-bold;
      color: $blueTitle;
      margin-bottom: 1.5em;
    }
    .long-description {
      white-space: pre-line;
    }
    .small-description {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }
}
.team-container {
  bottom: 0;
  align-items: flex-start;
  background-color: #efeaf4;

  .team-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 500px;
    padding: 5em 6% 10em 6%;
    background-color: $dark-blue-team;
    clip-path: polygon(0 0%, 100% 0, 100% 95%, 0 100%);
    @media screen and (max-width: $breakMobile) {
      clip-path: polygon(0 0%, 100% 0, 100% 99.5%, 0 100%);
      padding: 2em 6% 6em 6%;
    }
    @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
      clip-path: polygon(0 0%, 100% 0, 100% 99%, 0 100%);
      padding: 4em 6% 7em 6%;
    }
    h2 {
      text-align: center;
      color: $white;
      margin-bottom: 1em;
    }
    .team-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, auto));
      width: 100%;

      column-gap: 4em;
      row-gap: 2.5em;
      position: relative;
      z-index: 3;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $breakTab) {
        column-gap: 1em;
        row-gap: 1em;
      }
      .grid-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 280px;
        width: 240px;
        background-color: $white;
        border-radius: 10px;
        padding: 1.5em 0.5em;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
        text-decoration: none;
        .team-image {
          justify-content: center;
          align-items: center;
          .image {
            width: 120px;
            height: 120px;
            border: 3px solid $green;
            img {
              object-fit: cover;
              object-position: left;
            }
          }
        }
        h4 {
          font-size: 1.25em;
          font-family: bold;
          color: $blueTitle;
          margin: 1em 0 0.5em 0;
        }
        span {
          font-size: 1em;
          line-height: 1em;
          font-family: semi-bold;
          color: $grey;
          text-align: center;
          //text-transform: capitalize;
        }
      }
    }
  }
  .floral-background {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    min-height: 820px;
    background-image: url("../../images/floral-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
}
.journalists-container {
  bottom: 0;
  align-items: flex-start;
  background-color: #efeaf4;
  padding: 4em 6% 8% 6%;
  min-height: 500px;
  @media screen and (max-width: $breakMobile) {
    padding: 1em 6% 4em 6%;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    padding: 3em 6% 7em 6%;
  }
  .journalists-wrapper {
    h2 {
      color: $blueTitle;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 1.25em;
      line-height: 1em;
      font-family: semi-bold;
      color: $grey;
      text-align: left;
      margin-bottom: 2em;
      @media screen and (max-width: $breakSmallTab) {
        font-size: 1em;
      }
    }
    .journalists-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(155px, auto));
      width: 100%;
      column-gap: 2em;
      row-gap: 1.5em;
      position: relative;
      z-index: 2;
      @media screen and (max-width: $breakMobile) {
        grid-template-columns: repeat(auto-fill, minmax(140px, auto));
      }
      .grid-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        @media screen and (max-width: $breakMobile) {
          grid-template-columns: repeat(auto-fill, minmax(140px, auto));
        }
        .journalist-image {
          justify-content: center;
          align-items: center;
          .image {
            width: 100px;
            height: 100px;
            img {
              object-fit: cover;
              object-position: left;
            }
          }
        }
        h4 {
          font-size: 0.875em;
          font-family: unbuntu-medium;
          color: $blueTitle;
          margin: 1em 0 0.5em 0;
        }
        span {
          font-size: 0.875em;
          line-height: 1em;
          font-family: unbuntu-medium;
          color: $grey;
          text-align: center;
          //text-transform: capitalize;
        }
      }
    }
  }
  .journalists-shape {
    position: absolute;
    bottom: 20%;
    right: 0;
    width: 100%;
    min-height: 1600px;
    background-image: url("../../images/qui-sommes-nous/journalists-shape.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    @media screen and (max-width: $breakMobile) {
      right: 0;
      bottom: 50%;
    }
    @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
      right: 0;
      bottom: 40%;
    }
  }
}
.network-container {
  bottom: 0;
  align-items: flex-start;
  background-color: #efeaf4;
  .network-wrapper {
    width: 100%;
    padding: 4em 6% 10% 6%;
    background-color: $green;
    clip-path: polygon(0 0%, 100% 0, 100% 93%, 0 100%);
    min-height: 500px;
    @media screen and (max-width: $breakMobile) {
      clip-path: polygon(0 0%, 100% 0, 100% 99%, 0 100%);
      padding: 2em 6% 4em 6%;
    }
    @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
      clip-path: polygon(0 0%, 100% 0, 100% 99%, 0 100%);
      padding: 3em 6% 5em 6%;
    }
    h2 {
      color: $blueTitle;
      margin-bottom: 0.5em;
      text-transform: uppercase;
    }
    p {
      font-size: 1.25em;
      line-height: 1em;
      font-family: semi-bold;
      color: $grey;
      text-align: left;
      margin-bottom: 2em;
      @media screen and (max-width: $breakSmallTab) {
        font-size: 1em;
      }
    }
    .network-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, auto));
      width: 100%;
      column-gap: 0.65em;
      row-gap: 1em;
      .grid-element {
        display: grid;
        grid-template-columns: 100px 220px;
        height: 105px;
        column-gap: 10px;
        background-color: $white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
        text-decoration: none;
       
        .network-image {
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 105px;
          .image {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: left;
              transform-origin: 50% 65%;
              transition: transform 2s, filter 1s ease-in-out;
            }
            img:hover {
              transform: scale(1.2);
            }
          }
        }
        .text-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          h4 {
            font-size: 1.25em;
            font-family: bold;
            color: $blueTitle;
            margin-bottom: 0.25em;
            max-width: 90%;
          }
          span {
            font-size: 1em;
            line-height: 1em;
            font-family: semi-bold;
            color: $grey;
            text-align: left;
            //text-transform: capitalize;
          }
        }
      }
    }
  }
}
.supports-container {
  bottom: 0;
  align-items: flex-start;
  background-color: #efeaf4;
  padding: 4em 6% 8% 6%;
  @media screen and (max-width: $breakMobile) {
    padding: 2em 6% 6em 6%;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    padding: 3em 6% 5em 6%;
  }
  .supports-wrapper {
    min-height: 500px;
    h2 {
      color: $blueTitle;
      margin-bottom: 1em;
    }
    p {
      font-size: 1.25em;
      line-height: 1em;
      font-family: semi-bold;
      color: $grey;
      text-align: left;
      margin-bottom: 2em;
    }
    .supports-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, auto));
      width: 100%;
      column-gap: 10px;
      row-gap: 1em;
      .grid-element {
        display: grid;
        grid-template-columns: 30% 70%;
        height: 105px;
        column-gap: 10px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
        text-decoration: none;
        .supports-image {
          justify-content: center;
          align-items: center;
          .image {
            width: 70px;
            height: 70px;
            img {
              object-fit: cover;
              object-position: left;
            }
          }
        }
        .text-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          h4 {
            font-size: 1.25em;
            font-family: bold;
            color: $blueTitle;
            margin-bottom: 0.25em;
            max-width: 90%;
          }
          span {
            font-size: 1em;
            line-height: 1em;
            font-family: semi-bold;
            color: $grey;
            text-align: left;
            //text-transform: capitalize;
          }
        }
      }
    }
  }
}
