@import "../base/var";
@import "../base/fonts";
@import "../base/buttons";

.error-message {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5% 3%;
  background-color: $alert-background;
  span {
    font-family: medium;
    font-size: 0.875em;
    color: $alert;
    margin-left: 10px;
  }
}

.success-message {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5% 3%;
  background-color: $success-background;
  span {
    font-family: medium;
    font-size: 0.875em;
    color: $green-success-message;
    margin-left: 10px;
  }
}
