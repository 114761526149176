@import "../base/var";
@import "../base/fonts";

.main {
  display: flex;
  flex-direction: column;
}
.full-width {
  width: 100%;
}
.background-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 3em;
  @media screen and (max-width: $breakDesktop) {
    padding: 0 6%;
  }
  @media screen and (max-width: $breakSmallTab) {
    min-height: 35em;
  }
  min-height: 43em;
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    @media screen and (max-width: $breakSmallTab) {
      font-size: 3em;
      margin-top: 1.5em;
      width: 100%;
    }
    font-size: 4.75em;
    font-family: bold;
    line-height: 1em;
    color: $white;
    margin: 0.75em 0 2.25em 0;
    width: 90%;
  }
  .shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }
}
.container {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text-container {
      width: 100%;
      h2 {
        @media screen and (max-width: $breakMobile) {
          font-size: 2em;
        }
        @media screen and (max-width: $breakSmallTab) {
          padding-top: 0.75em;
        }
        @media screen and (min-width: $breakSmallTabMin) and (max-width: 1229px) {
          font-size: 2em;
        }
        font-size: 2.5em;
        font-family: bold;
        color: $blueTitle;
        margin-bottom: 0.75em;
      }
      p {
        @media screen and (max-width: $breakMobile) {
          font-size: 1em;
        }
        @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
          font-size: 1.25em;
          line-height: 1.15em;
        }
        @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
          font-size: 1em;
        }

        font-size: 1.25em;
        line-height: 1.15em;
        font-family: semi-bold;
        color: $blueTitle;
      }
    }
  }
}
