@font-face {
  font-family: "medium";
  src: local("BrandingMedium"),
    url("../../fonts/Branding-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "semi-bold";
  src: local("BrandingSemibold"),
    url("../../fonts/Branding-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "bold";
  src: local("BrandingBold"),
    url("../../fonts/Branding-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "unbuntu-medium";
  src: local("UnbuntuMedium"),
    url("../../fonts/Ubuntu-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "YesevaOne";
  src: local("YesevaOne"),
    url("../../fonts/yesevaone-regular.ttf") format("truetype");
}
