@import "../base/var";
@import "../base/fonts";

.switcher-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .switcher-wrapper {
    display: flex;
    flex-direction: row;
    width: 450px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background-color: $white-transparency-2;
    border-radius: 31px;
    margin-top: 7em;
    padding: 0 5px;
    @media screen and (max-width: $breakMobile) {
      width: 100%;
    }
    .switcher-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50px;
      min-width: 33%;
      font-size: 1em;
      font-family: semi-bold;
      color: $blueTitle;
      text-decoration: none;
      box-shadow: none;
      cursor: pointer;
      @media screen and (max-width: $breakMobile) {
        font-size: 0.75em;
      }
    }
    .switcher-item:hover {
      color: $blue;
    }
    .switcher-item-selected {
      background-color: $green;
      border-radius: 24px;
      cursor: pointer;
      box-shadow: none;
    }
    .switcher-item-selected:hover {
      color: $blueTitle;
    }
  }
}
