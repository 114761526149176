@import "../base/var";
@import "../base/fonts";

.input-floating {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: 1em;
  margin-bottom: 5px;
  width: 100%;
  background-color: rgba(24, 43, 67, 0.05);
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
    margin-top: 1em;
  }
  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: $blue-radio-label;
  }

  .input-container .filled {
    transform: translate(0, 12px) scale(0.8);
    color: $blue-radio-label;
  }

  .input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: $blue-radio-label;
    font-size: 1em;
    font-family: medium;
    line-height: 1;
    left: 16px;
  }
  .input-container input {
    height: 60px;
    border-radius: 8px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 1em;
    font-family: semi-bold;
    color: $blue-marina;
    background-color: transparent;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .input-container input:disabled {
    height: 60px;
    border-radius: 8px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 1em;
    font-family: semi-bold;
    color: $blue-marina;
    line-height: 1;
    background-color: $very-light-grey;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .required-only {
    width: 50px;
    height: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    padding-top: 5px;
    padding-right: 10px;
  }
  .required-right-icon {
    width: 50px;
    height: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: none;
  }
  span {
    text-transform: capitalize;
    font-size: 0.75em;
    color: $grey;
    font-family: medium;
  }
  i,
  .right-icon {
    width: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    @media screen and (max-width: $breakSmallTab) {
      margin-right: 10px;
    }
    .icon {
      path {
        fill: $dark-blue;
      }
    }
    .icon:hover {
      path {
        fill: $blue-marina;
      }
    }
  }
}

.input-container-message {
  padding: 0 1em;
  width: 100%;
}

.textarea {
  min-height: 200px;
  width: 100%;
  border-radius: 8px;
  padding: 24px 16px 4px 16px;
  font-size: 1em;
  font-family: medium;
  color: $blue-marina;
  background-color: transparent;
  line-height: 1;
  outline: none;
  box-shadow: none;
  resize: none;
  margin: 0.25em 0;
  background-color: rgba(24, 43, 67, 0.05);
}

.textarea:placeholder {
  color: $blue-marina;
  font-size: 1em;
  font-family: medium;
}

.input-border {
  border: 1px solid $grey-input-border;
}
.input-border-error {
  border: 1px solid $alert;
}

.input-border-error-newsletter-footer {
  border: 1.5px solid $alert;
}
