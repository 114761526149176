@import "../base/var";
@import "../base/fonts";

#intro {
  padding: 1em 1em 0em 1em;
}

#field-type {
  font-size: 1.25em;
  font-family: bold;
  color: $dark-blue;
}

.share-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
  width: 50%;
  @media screen and (max-width: $breakMobile) {
    width: 90%;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakDesktop) {
    width: 70%;
  }
  table {
    width: 100%;
    margin-bottom: 1.25em;

    tr > td {
      width: 50%;
      padding: 0 1em;

      @media screen and (max-width: $breakSmallTab) {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
