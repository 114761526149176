@import "../base/var";
@import "../base/fonts";

header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #21639e 0%, #6c3490 100%);
  z-index: 100;
  padding: 0 3em;
  box-shadow: 0 11px 20px rgb(0 0 0 / 50%);
  @media screen and (max-width: $breakDesktop) {
    padding: 0 6%;
  }
  .inexplore-logo {
    img {
      height: 30px;
      @media screen and (max-width: $breakMobile) {
        height: 25px;
      }
    }
  }
  .menu-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.header-icons {
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    .user-logged {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      background-color: rgba(36, 38, 56, 0.53);
      @media screen and (max-width: $breakMobile) {
        margin-left: 15px;
      }
      span {
        text-transform: uppercase;
        font-size: 1em;
        font-family: YesevaOne;
        color: $white;
      }
    }
  }
  a:hover {
    transform: scale(1.2);
  }
  .icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7496%)
      hue-rotate(193deg) brightness(112%) contrast(101%);
    margin-left: 30px;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: $breakMobile) {
      margin-left: 15px;
    }
  }
  .icon:hover {
    transform: scale(1.2);
  }
}
