@import "./var";
@import "./fonts";

/* buttons styles */

.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 50px;
  background: $white;
  border: none;
  border-radius: 25px;
  font-family: semi-bold;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: $dark-blue;

  @media screen and (max-width: $breakMobile) {
    width: 43%;
    margin-right: 5px;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    min-width: 43%;
    margin-right: 10px;
  }
  @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
    margin: 0;
  }
}
.btn-action:hover {
  color: $white;
  background: $dark-blue;
}

.btn-social-profil {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  @media screen and (max-width: $breakMobile) {
    margin: 0 3px;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    margin: 0 10px;
  }
  @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
    margin-left: 1em;
  }
}
.btn-social-profil:hover {
  transform: scale(1.2);
}

.universe-container {
  position: relative;
  .universe-button {
    border-radius: 24px;
    background: linear-gradient(117.78deg, #6c60cc 0%, #8548bb 100%);
    border: none;
    min-width: 210px;
    top: 0px;
    position: relative;
    font-family: semi-bold;
    font-size: 1em;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    transition: box-shadow 0.3s;
    .universe-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(7496%) hue-rotate(193deg) brightness(112%) contrast(101%);
    }
    .chevron-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(7496%) hue-rotate(193deg) brightness(112%) contrast(101%);
      transition: transform 280ms ease-out 50ms;
    }
  }
  .universe-button-mobile {
    top: 0px;
    position: relative;
    .universe-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(7496%) hue-rotate(193deg) brightness(112%) contrast(101%);
    }
  }
  .universe-button-mobile:hover {
    .universe-icon {
      transform: scale(1.3);
      transition: all 0.2s ease-in-out;
    }
  }
  .universe-popin {
    position: absolute;
    padding-top: 1.5em;
    z-index: 45;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-15%);
  }
}
.universe-container:hover {
  .universe-button {
    box-shadow: 0 0 15px 0 #8548bb;
    .chevron-icon {
      transform: rotate(180deg);
      transition: transform 280ms ease-in 50ms;
    }
  }
  .universe-popin {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    @media screen and (max-width: $breakMobile) {
      transform: translateY(15px) translateX(-10px);
    }
    .universe-popin-container {
      overflow: hidden;
      ul {
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 10px;
        border-radius: 15px;
        li {
          cursor: pointer;
          padding-left: 10px;
          border-radius: 8px 0 0 8px;
          a {
            width: 120px;
           
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.35em;
            padding: 6px;
            position: relative;
            img {
              display: flex;
              flex-shrink: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.btn-footer-newsletter {
  width: 20%;
  height: 45px;
  display: block;
  border: none;
  background-color: $dark-blue-footer;
  margin-right: 3px;
  border-radius: 30px;
  cursor: pointer;
}
.btn-footer-newsletter:hover {
  background-color: $blue;
}
.btn-send-form {
  width: 30%;
  height: 50px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: $dark-blue;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  margin-top: 3em;
  @media screen and (max-width: $breakSmallTab) {
    width: 80%;
  }
  span {
    display: block;
    font-family: semi-bold;
    font-size: 1.25em;
    color: $white;
  }
}
.btn-send-form:hover {
  background-color: $white;
  border: 2px solid $dark-blue;
  span {
    color: $dark-blue;
  }
}
