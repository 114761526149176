@import "../base/_var";
@import "../base/_fonts";


.valeurs-header {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 80%, #2e314e 100%),
    url("../../images/nos-valeurs/header-background-image.jpg");
  #shape-valeurs {
    bottom: -2px;
  }
}
.valeurs-color-background {
  background: linear-gradient(90deg, #1d4c7e 30%, #99d0c1 100%);
}
.valeurs-container {
  position: relative;
  height: auto;
  bottom: 80px;
  z-index: 3;
  background: linear-gradient(90deg, #1d4c7e 30%, #99d0c1 100%);;
  @media screen and (max-width: $breakMobile) {
    bottom: 10px;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakDesktop) {
    bottom: 40px;
  }
  .section-wrapper {
    padding: 0em 3em;
    p {
      text-align: left;
      font-size: 1.75em;
      line-height: 1em;
      letter-spacing: -0.56px;
      font-family: bold;
      color: $white;
      span {
        color: $yellow;
      }
      @media screen and (max-width: $breakMobile) {
        font-size: 1.25em;
      }
      @media screen and (min-width: $breakMobileMin) and (max-width: $breakDesktop) {
        font-size: 1.5em;
      }
    }
    @media screen and (max-width: $breakDesktop) {
      justify-content: center;
      padding: 0em 6%;
    }
    .floral-background {
      position: absolute;
      top: 40px;
      right: 80px;
      width: 100%;
      min-height: 750px;
      background-image: url("../../images/nos-valeurs/floral-background.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.5;
      z-index: 1;
    }
    ul {
      margin: 6em 0 4em 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, 30%);
      column-gap: 5%;
      row-gap: 5em;
      width: 100%;
      list-style: none;

      @media screen and (max-width: $breakSmallTab) {
        grid-template-columns: repeat(1, 1fr);
        margin: 6em 0;
      }
      @media screen and (min-width: $breakSmallTabMin) and (max-width: $breakTab) {
        grid-template-columns: repeat(2, 1fr);
      }
      li {
        position: relative;
        width: 100%;
        height: 370px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(25, 27, 50, 0.62);
        border-radius: 10px;

        .circle {
          width: 100px;
          height: 100px;
          position: relative;
          top: -50px;
          border-radius: 100px;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease 0s;
          img {
            width: 100%;
            height: auto;
          }
        }
        .circle:hover{
          transform: scale(1.2);
        }

        img {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          content: url("../../images/nos-valeurs/card-bottom.png");
        }
        .text-container {
          width: 100%;
          position: relative;
          bottom: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0 2em 0 2em;
          @media screen and (max-width: $breakMobile) {
            bottom: 40px;
          }
          h2 {
            text-align: left;
            font-size: 1.875em;
            line-height: 1em;
            font-family: bold;
            color: $white;
            margin-bottom: 1em;
          }
          p {
            text-align: left;
            font-size: 1.125em;
            line-height: 1em;
            font-family: semi-bold;
            color: #a3abb8;
          }
        }
      }
    }
  }
}
