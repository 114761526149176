@import "../base/_var";
@import "../base/_fonts";

.wave {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.histoire-header {
  background-image: url("../../images/notre-histoire/header-background-image.jpg");
}
.main-container {
  background-color: $green-transparency;
  width: 100%;
  .histoire-container {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/notre-histoire/container-background.png");
    clip-path: polygon(0 0%, 100% 0, 100% 97%, 0 100%);
    position: relative;
    bottom: 100px;
    background-color: $green;
    @media screen and (min-width: $breakSmallTabMin) and (max-width: 1229px) {
      bottom: 50px;
      clip-path: polygon(0 0%, 100% 0, 100% 98%, 0 100%);
    }
    @media screen and (max-width: $breakSmallTab) {
      bottom: 25px;
      clip-path: polygon(0 0%, 100% 0, 100% 98%, 0 100%);
    }
    z-index: 4;
    .section-wrapper {
      padding: 1em 3em;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 3em;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $breakTab) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2em;
      }
      @media screen and (max-width: $breakDesktop) {
        padding: 0em 6%;
      }
    }
    .histoire-mystere {
      padding-bottom: 5%;
      z-index: 3;
      bottom: 0;
      position: relative;
      @media screen and (max-width: $breakMobile) {
        padding-bottom: 10%;
      }
      p {
        @media screen and (min-width: $breakDesktop) {
          margin-bottom: 2em;
        }
      }
    }
    .histoire-aventure {
      @media screen and (max-width: $breakMobile) {
        padding: 6em 6% 5em 6%;
      }
      @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
        padding: 6em 6% 6em 6%;
      }
      @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
        padding: 6em 6% 5em 6%;
      }

      position: relative;
      padding: 8em 3em 10em 3em;
      width: 100%;
      clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 98%);
      background-color: rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
    .histoire-inexplore {
      padding: 5em 3em 10em 3em;
      @media screen and (max-width: $breakMobile) {
        padding: 1em 6% 7em 6%;
      }
      @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
        padding: 1em 6% 9em 6%;
      }
      @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
        padding: 4em 6% 6em 6%;
      }
      width: 100%;
      position: relative;
      bottom: 0;
      z-index: 3;
      h2,
      p,
      img {
        position: relative;
        bottom: 0;
        z-index: 3;
      }
    }
    .floral-background-histoire-mystere {
      position: absolute;
      top: 380px;
      left: 0;
      width: 100%;
      min-height: 820px;
      background-image: url("../../images/floral-background.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
      @media screen and (max-width: $breakMobile) {
        top: 380px;
      }
      @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
        top: 200px;
      }
      @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
        top: 300px;
      }
    }
    .floral-background-histoire-inexplore {
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      min-height: 820px;
      background-image: url("../../images/floral-background.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      @media screen and (max-width: $breakMobile) {
        bottom: 0px;
      }
      @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
        top: 10px;
        bottom: 0;
      }
      @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
        top: 250px;
        bottom: 0;
      }
    }
    .histoire-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 3;
      .mystere-image {
        content: url("../../images/notre-histoire/mystere-transformation.jpg");
      }
      .aventure-image {
        content: url("../../images/notre-histoire/aventure-pionniers.jpg");

        @media screen and (max-width: $breakTab) {
          order: 1;
        }
        .inexplore-image {
          content: url("../../images/notre-histoire/explorer-inexplore.jpg");
        }
      }
      img {
        transform-origin: 50% 65%;
        transition: transform 2s, filter 1s ease-in-out;
        width: 100%;
        height: 100%;
      }
      img:hover {
        transform: scale(1.2);
      }
    }

    .inexplore-image {
      content: url("../../images/notre-histoire/explorer-inexplore.jpg");
    }
  }
}

.video-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $green-transparency;
  padding: 0em 3em 18em 3em;
  @media screen and (max-width: $breakMobile) {
    padding: 0em 6% 8em 6%;
  }
  @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
    padding: 0em 6% 12em 6%;
  }
  @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
    padding: 0em 6% 18em 6%;
  }

  h2 {
    @media screen and (max-width: $breakMobile) {
      font-size: 2em;
    }
    text-align: center;
    font-size: 2.5em;
    font-family: bold;
    color: $blueTitle;
    margin-bottom: 2em;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.thank-container {
  position: relative;
  width: 100%;
  z-index: 2;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #21639e 0%, #6c3490 100%);
    clip-path: polygon(0 0%, 100% 0, 100% 85%, 0 100%);
    padding: 3em 10em 10em 10em;
    @media screen and (max-width: $breakTab) {
      padding: 0em 2em 8em 2em;
    }

    h2 {
      font-size: 5em;
      font-family: bold;
      color: $green;
      margin-bottom: 0.75em;
      @media screen and (max-width: $breakMobile) {
        font-size: 3em;
      }
      @media screen and (min-width: $breakSmallTabMin) and (max-width: 1229px) {
        font-size: 4em;
      }
    }
    p {
      text-align: center;
      font-size: 1.25em;
      line-height: 1.25em;
      font-family: semi-bold;
      color: $white;
      @media screen and (max-width: $breakMobile) {
        font-size: 1em;
      }
    }
  }
  .floral-background {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: url("../../images/floral-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    @media screen and (max-width: $breakMobile) {
      bottom: 0px;
    }
    @media screen and (min-width: $breakMobileMin) and (max-width: $breakTab) {
      top: 10px;
      bottom: 0;
    }
    @media screen and (min-width: $breakTabMin) and (max-width: 1229px) {
      top: 250px;
      bottom: 0;
    }
  }
}

.share {
  position: relative;
  display: flex;
  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4em 0 5em 0;
  background-color: $white;
  @media screen and (max-width: $breakMobile) {
    padding: 2em 0 5em 0;
  }
  h2 {
    @media screen and (max-width: $breakMobile) {
      font-size: 2em;
    }
    text-align: center;
    font-size: 2.5em;
    font-family: bold;
    color: $blueTitle;
    margin-bottom: 0.75em;
  }
  h3 {
    text-align: center;
    font-size: 1.125em;
    font-family: semi-bold;
    color: $blueTitle;
    margin-bottom: 2em;
  }
  .socials-networks {
    width: 45%;
    @media screen and (max-width: $breakDesktop) {
      width: 100%;
      padding: 0 6%;
    }
    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    ul > li {
      display: inline-block;
      margin: 5px 15px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.875em;
        line-height: 1.5625em;
        font-family: semi-bold;
        color: $light-grey-footer;
        text-decoration: none;
        img {
          height: 20px;
        }
        .icon {
          margin-bottom: 5px;
          filter: invert(90%) sepia(30%) saturate(100%) hue-rotate(13deg)
            brightness(0%) contrast(92%);
        }
        color: $blueTitle;
      }
      a:hover {
        .icon {
          filter: invert(51%) sepia(67%) saturate(6733%) hue-rotate(207deg)
            brightness(100%) contrast(101%);
        }
        color: $blue;
      }
    }
  }
}
.share-form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 0 0 4em 0;
  h2 {
    @media screen and (max-width: $breakMobile) {
      font-size: 2em;
    }
    text-align: center;
    font-size: 2.5em;
    font-family: bold;
    color: $blueTitle;
    margin-bottom: 1.5em;
    padding: 0 6%;
  }
}
